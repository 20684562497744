import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AddCircle from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import HouseDetail from "../HouseDetail";
import querystring from "querystring";

const WhereIsRow = ({ chain, house, startdate, enddate, title }) => {
  const [showInfo, setShowInfo] = useState(false);
  const scheduleQueryString = querystring.encode({
    startdate,
    enddate,
    title,
  })
  return (
    <TableRow>
      <TableCell>
        <IconButton onClick={() => setShowInfo(true)} align="right">
          <InfoIcon />
        </IconButton>
        <IconButton
          href={
            chain.id
              ? `/schedule/${chain.id}/${house.houseId}?${scheduleQueryString}`
              : `/schedule/${house.houseId}?${scheduleQueryString}`
          }
          target="_blank"
        >
          <CalendarTodayIcon />
        </IconButton>
        {chain.id ? (
          <IconButton href={`/schedule/${chain.id}?${scheduleQueryString}`} target="_blank">
            <AddCircle />
          </IconButton>
        ) : null}

        <HouseDetail
          open={showInfo}
          house_id={house.houseId}
          onClose={() => setShowInfo(false)}
          fullScreen={false}
        />
      </TableCell>
      <TableCell title={house.movieName}>{house.movieName}</TableCell>
      <TableCell
        title={house.houseName}
        style={{ fontWeight: "bold" }}
        align="left"
      >
        {house.houseName}
      </TableCell>
      <TableCell>{house.houseId}</TableCell>
      <TableCell title={house.altId} style={{ fontWeight: "bold" }}>
        {house.altId}
      </TableCell>
      <TableCell>{house.screenCount}</TableCell>
      <TableCell>{house.city}</TableCell>
      <TableCell>{house.state}</TableCell>
    </TableRow>
  );
};

export default WhereIsRow;
